import React from "react"
import SigninButton from "../Components/SigninButton"

function Login() {
    return (
        <div className="width-100 flex-row justify-center align-center h-70v login-div">
            <div>
                <h2 className="gray-text">You aren't authenticated.</h2>
                <span>Please Sign in</span>
                <SigninButton type="primary" style={{ width: "fit-content" }} />
            </div>
        </div>
    )
}

export default Login
