import { UPDATE_USER } from "../types/userActionTypes"

const user = {}

const userReducer = (state = user, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return action.payload
        default:
            return state
    }
}

export default userReducer
