
import { getLazyComponent } from "./lazy"

const Home = getLazyComponent("Pages", "Home.js")
const Ocean = getLazyComponent("Pages", "Ocean.js")
const Air = getLazyComponent("Pages", "Air.js")
const Road = getLazyComponent("Pages", "Road.js")

export const RouteArray = [
    { id: 1, path: "/", component: <Home />, private: false },
    { id: 2, path: "/home", component: <Home />, private: false },
    { id: 3, path: "/air", component: <Air />, private: false },
    { id: 4, path: "/ocean", component: <Ocean />, private: false },
    { id: 5, path: "/road", component: <Road />, private: false },
    { id: 6, path: "*", component: <Home />, private: false },

]
