import React from "react"
import { NavLink } from "react-router-dom"
import { useIsAuthenticated, AuthenticatedTemplate } from "@azure/msal-react"
import SignoutButton from "./SignoutButton"
import { useSelector } from "react-redux"
import { UserOutlined, MailOutlined } from "@ant-design/icons"
import { getUserProfilePicture } from "../Redux/actions/userActions"
import { DupontLogo } from "../Assets"

function Header() {
    const isAuthenticated = useIsAuthenticated()
    const user = useSelector((store) => store.user)
    return (
        <div className="header">
            <div className="first flex-row align-center justify-space-between">
                <div className="left flex-row align-center">
                    <h1 className="red-text">W&P</h1>
                    <span className="gray-text">
                        Intelligent Logistics Capacity Planner
                    </span>
                </div>
                <div className="right flex-row align-center">
                    <ul className="list-style-none flex-row align-center">
                        {/* <li className="m-l-large">Need help?</li> */}
                        {isAuthenticated && (
                            <li className="m-l-large">
                                <UserOutlined style={{ marginRight: 5 }} />
                                {user.name}
                            </li>
                        )}
                        <li className="m-l-large align-center ">
                            <a
                                href="mailto:timothy.payne@dupont.com?subject= Feedback on WP-ILP "
                                className="text-decoration-none black-text"
                            >
                                Support <MailOutlined />
                            </a>
                        </li>
                        <li className="m-l-large">
                            {isAuthenticated && <SignoutButton />}
                        </li>
                        <li className="m-l-large">
                            <img
                                src={DupontLogo}
                                alt=""
                                className="dupont-logo"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div className="second">
                <ul className="flex-row align-center  nav list-style-none">
                    <li className="m-r-huge">
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <AuthenticatedTemplate>
                        <li className="m-r-huge">
                            <NavLink to="/Ocean">Ocean</NavLink>
                        </li>
                        <li className="m-r-huge">
                            <NavLink to="/Air">Air</NavLink>
                        </li>
                        <li className="m-r-huge">
                            <NavLink to="/Road">Road</NavLink>
                        </li>
                    </AuthenticatedTemplate>
                </ul>
            </div>
        </div>
    )
}

export default Header
