import { Button } from "antd"

export const IconButton = ({
    Icon,
    text,
    onClick,
    spin = false,
    type = "default",
    style = {},
}) => {
    return (
        <Button
            className="flex-row align-center large-padding ml-medium"
            icon={Icon && <Icon spin={spin} />}
            style={style}
            onClick={onClick}
            type={type}
        >
            {text}
        </Button>
    )
}
export default IconButton
