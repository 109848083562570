import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { RouteArray } from "./Routes"
import Header from "../Components/Header"
import ProtectedRoute from "../Components/ProtectedRoute"

function Routing() {
    return (
        <Router>
            <Header />
            <Routes>
                {RouteArray.map(
                    (route) =>
                        !route.private && (
                            <Route
                                key={route.id}
                                path={route.path}
                                element={
                                    <ProtectedRoute>
                                        {route.component}
                                    </ProtectedRoute>
                                }
                            />
                        )
                )}
            </Routes>
        </Router>
    )
}

export default Routing
