import {
    UPDATE_DUPLICATE_OCEAN_DATA,
    UPDATE_FILTERED_OCEAN_DATA,
    UPDATE_FORECAST_TYPE,
    UPDATE_OCEAN_COLUMNS,
    UPDATE_OCEAN_DATA,
    UPDATE_OCEAN_FORECAST,
} from "../types/oceanActionTypes"

const ocean = {
    data: null,
    duplicateData: null,
    filteredData: null,
    columns: null,
    forecast: [],
    forecastType: "quarter",
}

const oceanReducer = (state = ocean, action) => {
    switch (action.type) {
        case UPDATE_OCEAN_DATA:
            return { ...state, data: action.payload }
        case UPDATE_DUPLICATE_OCEAN_DATA:
            return { ...state, duplicateData: action.payload }
        case UPDATE_OCEAN_COLUMNS:
            return { ...state, columns: action.payload }
        case UPDATE_FILTERED_OCEAN_DATA:
            return { ...state, filteredData: action.payload }
        case UPDATE_OCEAN_FORECAST:
            return { ...state, forecast: action.payload }
        case UPDATE_FORECAST_TYPE:
            return { ...state, forecastType: action.payload }
        default:
            return state
    }
}
export default oceanReducer
