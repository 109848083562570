import React from "react"
import IconButton from "./IconButton"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../Config/Auth/authConfig"

function SignoutButton() {
    const { instance } = useMsal()

    const handleSignout = async () => {
        instance.logoutPopup(loginRequest).catch((err) => console.log(err))
    }
    return <IconButton text="Sign out" onClick={handleSignout} />
}

export default SignoutButton
