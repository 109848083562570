import { combineReducers } from "redux"
import air from "./reducers/airReducer"
import ocean from "./reducers/oceanReducer"
import road from "./reducers/roadReducer"
import user from "./reducers/userReducer"

export default combineReducers({
    user,
    air,
    ocean,
    road,
})
