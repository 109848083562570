import Routing from "./Utils/Routing"
import { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import { useDispatch, useSelector } from "react-redux"
import { fetchUser } from "./Redux/actions/userActions"

function App() {
    const { instance, accounts, inProgress } = useMsal()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUser(instance, accounts))
    }, [instance, accounts, inProgress])

    return <Routing />
}

export default App
