import React from "react"
import ReactDOM from "react-dom/client"
import "./Styles/index.css"
import App from "./App"
import DataProvider from "./Redux/DataProvider"
import { msalConfig } from "./Config/Auth/authConfig"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <MsalProvider instance={msalInstance}>
        <DataProvider>
            <App />
        </DataProvider>
    </MsalProvider>
)
