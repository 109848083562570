import { message } from "antd"
import { loginRequest } from "../../Config/Auth/authConfig"
import { UPDATE_USER } from "../types/userActionTypes"
import moment from "moment"
export const updateUser = (user) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_USER,
            payload: user,
        })
    } catch (err) {}
}

export const fetchUser = (instance, accounts) => async (dispatch) => {
    await instance
        .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        })
        .then((response) => {
            if (response) {
                dispatch(
                    updateUser({
                        token: response.accessToken,
                        idToken: response.idToken,
                        name: accounts[0].name,
                        username: accounts[0].username,
                        details: accounts[0],
                        expiresOn: response.expiresOn,
                        extExpiresOn: response.extExpiresOn,
                    })
                )
                console.log({
                    exp: moment(response.expiresOn).format(
                        "MM-DD-YYYY HH:MM:SS"
                    ),
                    now: moment(new Date()).format("MM-DD-YYYY HH:MM:SS"),
                    expiredCheck:
                        moment(response.expiresOn).format(
                            "MM-DD-YYYY HH:MM:SS"
                        ) < moment(new Date()).format("MM-DD-YYYY HH:MM:SS"),
                })
            }
        })
        .catch((err) => {})
}
