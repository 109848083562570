import {
    UPDATE_AIR_COLUMNS,
    UPDATE_AIR_DATA,
    UPDATE_FILTERED_AIR_DATA,
} from "../types/airActionTypes"

const air = { data: null, filteredData: null, columns: null }

const airReducer = (state = air, action) => {
    switch (action.type) {
        case UPDATE_AIR_DATA:
            return { ...state, data: action.payload }
        case UPDATE_AIR_COLUMNS:
            return { ...state, columns: action.payload }
        case UPDATE_FILTERED_AIR_DATA:
            return { ...state, filteredData: action.payload }
        default:
            return state
    }
}
export default airReducer
