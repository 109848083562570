import Loadable from "react-loadable"
import Loading from "../Components/Loading"

export const getLazyComponent = (componentType, component) => {
    // console.log()
    const Component = Loadable({
        loader: () => import(`../${componentType}/${component}`),
        loading: () => <Loading />,
    })
    return Component
}
