import React from "react"
import IconButton from "./IconButton"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../Config/Auth/authConfig"

function SigninButton({ type = "default", style }) {
    const { instance } = useMsal()
    const handleSignin = async () => {
        instance.loginRedirect(loginRequest).catch((err) => console.log(err))
    }
    return (
        <IconButton
            text="Sign in"
            type={type}
            style={style}
            onClick={handleSignin}
        />
    )
}

export default SigninButton
